.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important ;
}

.breadcrumb-start + .breadcrumb-start::before {
  content: var(--bs-breadcrumb-divider, "|") !important ;
}

.breadcrumb-disabled {
  pointer-events: none;
  opacity: 0.4;
  a {
    text-decoration: none;
  }
}

// Eula Section
.eula-section {
  padding-top: 1em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
}

.payment-links {
  border-bottom: 1px solid $border-black;
  padding-bottom: 1.5em;
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}

// Form Section
.form-section {
  border-top: 1px solid $border-black;
  padding-top: 1.5em;
}

// Eula Section
.eula-section {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
}

// Eula Section
.validate-section {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
}

// Payment Summary Section
.payment-section {
  padding-top: 0.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
  .cart-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 1px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .cart-part-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 0px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .cart-active {
    background-color: $border-black;
  }
}
