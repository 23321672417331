// Tester Contain
.tester-contain {
  padding-top: 0em;
  padding-top: 0em;
  padding-left: 1em;
  padding-right: 1em;
  [contenteditable]:focus {
    outline: 0px solid transparent;
  }
  // Unit Big
  .unit-big {
    // border-top: 1px solid $border-black;
    // Tester Nav
    .tester-nav {
      margin-bottom: 0.2em;
      position: relative;
    }
    // Weight Unit
    .weight-unit {
      padding-top: 0.3em;
      padding-left: 0.2em;
    }
    .weight {
      font-size: 1rem;
      padding-top: 0.5em;
      padding-right: 1em;
    }
    .system {
      font-size: 1rem;
      padding-top: 0.5em;
      padding-right: 1em;
      text-transform: capitalize;
    }
    // Size Unit
    .size-unit {
      padding-top: 0.6em;
      padding-right: 0.6em;
      .range {
        width: 6em;
      }
    }
    // Line Height Unit
    .line-height-unit {
      padding-top: 0.6em;
      padding-right: 0.6em;
      .range {
        width: 6em;
      }
    }
    // Align Unit
    .align-unit {
      padding-top: 0em;
      padding-right: 0.6em;
      .bi {
        font-size: 1rem;
      }
    }
    @-moz-document url-prefix() {
      .align-unit {
        padding-top: 0em;
        padding-right: 0.6em;
      }
      .bi {
        font-size: 1.2rem;
      }
    }
    // Char Unit
    .char-unit {
      padding-top: 0.12em;
      .btn {
        margin-right: 0.5rem;
      }
    }
    @-moz-document url-prefix() {
      .char-unit {
        padding-top: 0.2em;
        .btn {
          margin-right: 0.5rem;
        }
      }
    }
    // Options Unit
    .options-unit {
      padding-top: 0.3em;
      padding-right: 0;
    }
    @-moz-document url-prefix() {
      .options-unit {
        padding-top: 0.3em;
        padding-right: 0;
      }
    }
    // Axes Unit
    .axes-unit {
      padding-top: 0.3em;
      padding-left: 0.2em;
      .axes-text {
        font-size: 1rem;
        padding-top: 0.5em;
        padding-right: 1em;
      }
      .axes-range {
        padding-top: 0.6em;
        padding-right: 0.6em;
        .range {
          width: 6em;
        }
      }
    }
    // Overflow
    .overflow {
      overflow: hidden;
      white-space: nowrap;
    }
  }
  // Unit Small
  .unit-small {
    // border-top: 1px solid $border-black;
    .size-unit {
      padding-top: 0.2em;
      padding-right: 0.6em;
      .range {
        width: 100%;
      }
    }
    .text-unit {
      margin-top: 0em;
    }
    // Char Unit
    .char-unit {
      .btn {
        margin-right: 0.5rem;
      }
    }
    @-moz-document url-prefix() {
      .char-unit {
        .btn {
          margin-right: 0.5rem;
        }
      }
    }
    // Weight Unit
    .weight-unit {
      padding-top: 0.95em;
      padding-right: 0;
    }
    @-moz-document url-prefix() {
      .weight-unit {
        padding-top: 0.95em;
        padding-right: 0;
      }
    }
    .system {
      font-size: 1rem;
      padding-right: 1em;
      text-transform: capitalize;
    }
    // Options Unit
    .options-unit {
      padding-top: 0.5em;
      padding-right: 0;
    }
    @-moz-document url-prefix() {
      .options-unit {
        padding-top: 0.5em;
        padding-right: 0;
      }
    }
    // Axes Unit
    .axes-unit {
      padding-top: 0.95em;
      padding-right: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .tester-contain {
    padding-top: 0em;
    padding-top: 0em;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@include media-breakpoint-up(md) {
  .tester-contain {
    padding-top: 0em;
    padding-top: 0em;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@include media-breakpoint-up(lg) {
  .tester-contain {
    padding-top: 0em;
    padding-top: 0em;
    padding-left: 2em;
    padding-right: 2em;
  }
}

@include media-breakpoint-up(xl) {
  .tester-contain {
    padding-top: 0em;
    padding-top: 0em;
    padding-left: 2em;
    padding-right: 2em;
  }
}

// Font Description
.font-desc {
  padding-top: 4em;
  padding-bottom: 8em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

@include media-breakpoint-up(sm) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}

@include media-breakpoint-up(md) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}

@include media-breakpoint-up(lg) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}

@include media-breakpoint-up(xl) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}
