// Account Section
.account-section {
  padding-top: 1.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  border-bottom: 1px solid $border-black;
  .subhead {
    text-transform: uppercase;
    padding-bottom: 1.5em;
  }
  .account-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 1px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .account-part-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 0px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-fill {
    color: $cart-fill;
  }
}

// Invoice Section
.invoice-section {
  padding-top: 1.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  .subhead {
    text-transform: uppercase;
    padding-bottom: 1.5em;
  }
  .invoice-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 1px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .invoice-part-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 0px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .invoice-subtotal-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 0px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .invoice-total-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 0px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-fill {
    color: $cart-fill;
  }
  .cart-active {
    background-color: $border-black;
  }
}
