// Cart Contain

.cart-contain {
  padding-top: 8em;
  padding-bottom: 3em;
  .header {
    padding-bottom: 0.5em;
  }
  .subhead {
    text-transform: uppercase;
  }
  .text {
    padding-bottom: 1em;
  }
  .cart-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 1px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .cart-part-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 0px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .cart-active {
    background-color: $border-black;
  }
  .cart-form-link {
    padding-top: 2em;
  }
  .cart-fill {
    color: $cart-fill;
  }
}

@include media-breakpoint-up(sm) {
  .cart-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(md) {
  .cart-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(lg) {
  .cart-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(xl) {
  .cart-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

// Cart Summary Section
.summary-section {
  padding-top: 0.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  .cart-fill {
    color: $cart-fill;
  }
  .cart-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid $border-white;
    border-bottom: 1px solid $border-white;
    border-radius: 0px;
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-part-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid $border-white;
    border-radius: 0px;
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-vat-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px;
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-total-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-radius: 0px;
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-select {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  @include media-breakpoint-up(sm) {
    .cart-select {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }
  @include media-breakpoint-up(md) {
    .cart-select {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }
  @include media-breakpoint-up(lg) {
    .cart-select {
      margin-top: 0em;
      margin-bottom: 0em;
    }
  }
  @include media-breakpoint-up(xl) {
    .cart-select {
      margin-top: 0em;
      margin-bottom: 0em;
    }
  }
  .cart-form-link {
    padding-top: 2em;
  }
}

// Cart Checkout Section
.cart-checkout-section {
  padding-top: 0em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
}
