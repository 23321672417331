// IBM Plex
.ibm-plex-mono-ux {
  @include ibm-plex-mono-ux;
}

.ibm-plex-mono-bold {
  @include ibm-plex-mono-bold;
}

// Tester
.tester {
  @include tester;
}

// Omnium
.omnium {
  @include omnium;
}

// Omnium Wide
.omnium-wide {
  @include omnium-wide;
}

// Omnium Tagline
.omnium-tagline {
  @include omnium-tagline;
}

// Arbuckle
.arbuckle {
  @include arbuckle;
}

// Joachim
.joachim {
  @include joachim;
}

// Clarice
.clarice {
  @include clarice;
}

// District
.district {
  @include district;
}

// Homage Script
.homage-script {
  @include homage-script;
}

// Homage Condensed
.homage-condensed {
  @include homage-condensed;
}

// Kienan
.kienan {
  @include kienan;
}

// Metroflex Narrow
.metroflex-narrow {
  @include metroflex-narrow;
}

// Metroflex Uni
.metroflex-uni {
  @include metroflex-uni;
}

// Metroflex Wide
.metroflex-wide {
  @include metroflex-wide;
}

// Fono
.fono {
  @include fono;
}

// Modus
.modus {
  @include modus;
}

// Adore Mono
.adore-mono {
  @include adore-mono;
}

// Nice Weekend
.nice-weekend {
  @include nice-weekend;
}

// Rough Weekend
.rough-weekend {
  @include rough-weekend;
}

// Dotted Weekend
.dotted-weekend {
  @include dotted-weekend;
}

// Dotted Weekend
.archive {
  @include archive;
}

// Fuqua
.fuqua {
  @include fuqua;
}

// Fuqua Variable
.fuqua-variable {
  @include fuqua-variable;
}

// Kamaro
.kamaro {
  @include kamaro;
}

// Klif
.klif {
  @include klif;
}

// Karazan
.karazan {
  @include karazan;
}
