// Color
$primary: #606060;
$dark: #000000;
$border-black: rgba(0, 0, 0, 0.2);
$border-white: rgba(255, 255, 255, 0.2);
$cart-fill: rgba(0, 0, 0, 0);

// Off Canvas
$offcanvas-bg-color: #000000;
$offcanvas-color: #ffffff;

// Button Close
$btn-close-color: #ffffff;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 0.5;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: 1;

// Button Close
$form-range-track-bg: rgba(0, 0, 0, 0.125);

// Modal
$modal-content-bg: #000000;
$modal-content-color: #ffffff;
$modal-header-border-color: #3b3b3b;
$modal-footer-border-color: #3b3b3b;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px,
);
