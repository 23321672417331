//Font and Icon Imports
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

// Core variables mixins and webfonts
@import "variables";
@import "webfonts";
@import "mixins";
@import "keyframes";

// Bootstrap and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// Global CSS
@import "color";
@import "body";
@import "navigation";
@import "offcanvas";
@import "logo";
@import "home";
@import "fonts";
@import "collection";
@import "tester";
@import "response";
@import "section";
@import "account";
@import "cart";
@import "checkout";
@import "footer";
