// Html
html {
  scroll-padding-top: 180px;
}

// Body
body {
  @include ibm-plex-mono-ux;
  font-size: 1rem;
}
