// Nav Large
.navigation-large {
  padding-top: 1em;
  padding-left: 4em;
  padding-right: 4em;
  padding-bottom: 0.5em;
  border-bottom: 0px solid $border-black;
  .name {
    padding: 0.25em 0.3em 0.3em 0.3em;
    margin-bottom: 0.4em;
  }
  .info-nav {
    padding-left: 1.5em;
  }
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
  .menu-icon {
    font-size: 2rem;
    margin-left: 1em;
  }
}

// Nav Small
.navigation-small {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.8em;
  border-bottom: 0px solid $border-black;
  .name {
    font-size: 1.2rem;
  }
  .info-nav {
    padding-left: 1.5em;
  }
  .menu-icon {
    font-size: 2rem;
    margin-left: 1em;
  }
}

// Nav Overflow
.nav-overflow-list {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
  line-height: 2rem;
}

// Work Nav
.work-nav {
  margin-top: 5.5em;
  padding-top: 0em;
  padding-bottom: 0.5em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  font-size: 1em;
  .work-links {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    // padding-top: 0.1em;
    li {
      display: inline;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
  .work-item {
    padding-left: 1.5em;
  }
  .work-item-scheme {
    padding-left: 0.5em;
  }
  .scheme-circle {
    padding-top: 0.5em;
  }
  .white-scheme {
    color: #ffffff;
  }
  .black-scheme {
    color: #000000;
  }
}

@include media-breakpoint-up(sm) {
  .work-nav {
    margin-top: 5.5em;
    padding-top: 0em;
    padding-bottom: 0.5em;
    padding-left: 1.2em;
    padding-right: 1.2em;
  }
}

@include media-breakpoint-up(md) {
  .work-nav {
    margin-top: 5.9em;
    padding-top: 0em;
    padding-bottom: 0.5em;
    padding-left: 4em;
    padding-right: 4em;
  }
}

@include media-breakpoint-up(lg) {
  .work-nav {
    margin-top: 5.9em;
    padding-top: 0em;
    padding-bottom: 0.5em;
    padding-left: 4em;
    padding-right: 4em;
  }
}

@include media-breakpoint-up(xl) {
  .work-nav {
    margin-top: 5.9em;
    padding-top: 0em;
    padding-bottom: 0.5em;
    padding-left: 4em;
    padding-right: 4em;
  }
}
