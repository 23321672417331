// Footer

.footer-contain {
  margin-top: 30em;
  padding-top: 1em;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 2em;
  border-top: 1px solid $border-black;
  .footer-info {
    padding-top: 1em;
    padding-left: 0em;
    padding-right: 0em;
    padding-bottom: 0em;
    .pipe {
      padding-left: 0.7em;
      padding-right: 0.7em;
    }
  }
}

.fixed-footer {
  position: fixed;
  height: 120px;
  bottom: 0;
  width: 100%;
  padding-bottom: 2em;
}
