// Fonts Contain

.fonts-contain {
  padding-top: 10em;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  .detail-name {
    font-size: 2rem;
  }
}

@include media-breakpoint-up(sm) {
  .fonts-contain {
    padding-top: 10em;
  }
}

@include media-breakpoint-up(md) {
  .fonts-contain {
    padding-top: 10em;
  }
}

@include media-breakpoint-up(lg) {
  .fonts-contain {
    padding-top: 10em;
  }
}

@include media-breakpoint-up(xl) {
  .fonts-contain {
    padding-top: 10em;
  }
}

@media print {
  .fonts-contain {
    padding-top: 2em;
  }
}

// Fonts Name

.fonts-top {
  padding-top: 0em;
  padding-bottom: 0em;
  padding-left: 0em;
  padding-right: 0em;
  text-align: center;
  .font-name {
    font-size: 2rem;
  }
  .font-short {
    font-size: 1rem;
  }
}

// Fonts Square

.fonts-square {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  .spec {
    filter: drop-shadow(0.5rem 0.5rem 0.3rem rgb(0, 0, 0, 0.23));
  }
  .grid {
    padding-bottom: 1.5em;
  }
  .image-square:hover {
    opacity: 0.8;
  }
}

// Fonts Words

.fonts-words {
  margin-top: 1em;
  // margin-left: 1em;
  // margin-right: 1em;
  // border-top: 1px solid $border-black;
  // border-bottom: 1px solid $border-black;
  padding-top: 3em;
  padding-bottom: 2em;
  // padding-left: 4em;
  // padding-right: 4em;
  text-align: center;
  .font-section {
    font-size: 1.5rem;
    padding-bottom: 0.2rem;
    @include ibm-plex-mono-bold;
  }
}

// Fonts Spec

.fonts-spec {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  .font-section {
    font-size: 1.5rem;
    padding-bottom: 0.2rem;
    @include ibm-plex-mono-bold;
  }
  .spec {
    filter: drop-shadow(0.5rem 0.5rem 0.3rem rgb(0, 0, 0, 0.23));
  }
  .grid {
    padding-bottom: 1.5em;
  }
  .image-square:hover {
    opacity: 0.8;
  }
}

@include media-breakpoint-up(sm) {
  .fonts-spec {
    padding-left: 0em;
    padding-right: 0em;
  }
}

@include media-breakpoint-up(md) {
  .fonts-spec {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@include media-breakpoint-up(lg) {
  .fonts-spec {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@include media-breakpoint-up(xl) {
  .fonts-spec {
    padding-left: 5em;
    padding-right: 5em;
  }
}

// Fonts Use

.fonts-use {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  .font-section {
    font-size: 1.5rem;
    padding-bottom: 0.2rem;
    @include ibm-plex-mono-bold;
  }
  .spec {
    filter: drop-shadow(0.5rem 0.5rem 0.3rem rgb(0, 0, 0, 0.23));
  }
  .scale {
    filter: grayscale(0);
  }
  .grid {
    padding-bottom: 1.5em;
  }
  .image-square:hover {
    opacity: 0.8;
  }
}

@include media-breakpoint-up(sm) {
  .fonts-use {
    padding-left: 0em;
    padding-right: 0em;
  }
}

@include media-breakpoint-up(md) {
  .fonts-use {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@include media-breakpoint-up(lg) {
  .fonts-use {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@include media-breakpoint-up(xl) {
  .fonts-use {
    padding-left: 5em;
    padding-right: 5em;
  }
}

// Fonts Info

.fonts-info {
  // border-bottom: 1px solid $border-black;
  padding-top: 3em;
  // padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  .font-section {
    font-size: 1.5rem;
    @include ibm-plex-mono-bold;
  }
}

// Fonts License

.fonts-license {
  padding-top: 2em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  .font-section {
    font-size: 1.5rem;
    @include ibm-plex-mono-bold;
  }
  .subhead {
    text-transform: uppercase;
  }
  .license-name {
    text-transform: uppercase;
  }
  .card {
    background: none;
    padding-top: 1em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1.5em;
    // height: 15em;
    @include media-breakpoint-up(sm) {
      // height: 12em;
    }
    @include media-breakpoint-up(md) {
      height: 16em;
    }
    @include media-breakpoint-up(lg) {
      height: 14em;
    }
    @include media-breakpoint-up(xl) {
      height: 12em;
    }
    p {
      white-space: pre-line;
    }
  }
  .license-support:hover {
    background-color: $border-black;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  .license-active {
    background-color: $border-black;
  }
}

// Fonts select
.fonts-select {
  padding-top: 0em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  .subhead {
    text-transform: uppercase;
  }
  .font-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 1px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .part-card {
    background: none;
    border-left: 1px solid $border-black;
    border-right: 1px solid $border-black;
    border-top: 0px solid $border-black;
    border-bottom: 1px solid $border-black;
    border-radius: 0px;
  }
  .select-disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .select-active {
    background-color: $border-black;
  }
  .added {
    opacity: 0;
  }
}

// Fonts Detail

.fonts-detail {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  .spec {
    filter: drop-shadow(0.5rem 0.5rem 0.3rem rgb(0, 0, 0, 0.23));
  }
  .scale {
    filter: grayscale(0);
  }
  .grid {
    padding-bottom: 1.5em;
  }
  .image-square:hover {
    opacity: 0.8;
  }
}
