// IBM Plex Mono UX
@mixin ibm-plex-mono-ux {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

// IBM Plex Mono Bold
@mixin ibm-plex-mono-bold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

// Tester
@mixin tester {
  font-family: "Tester", sans-serif;
}

// Omnium
@mixin omnium {
  font-family: "Omnium", sans-serif;
}

// Omnium Wide
@mixin omnium-wide {
  font-family: "Omnium Wide", sans-serif;
}

// Omnium Tagline
@mixin omnium-tagline {
  font-family: "Omnium Tagline", sans-serif;
}

// Arbuckle
@mixin arbuckle {
  font-family: "Arbuckle", sans-serif;
}

// Joachim
@mixin joachim {
  font-family: "Joachim", serif;
}

// Clarice
@mixin clarice {
  font-family: "Clarice", serif;
}

// District
@mixin district {
  font-family: "District", sans-serif;
}

// Homage Script
@mixin homage-script {
  font-family: "Homage Script", sans-serif;
}

// Homage Condensed
@mixin homage-condensed {
  font-family: "Homage Condensed", sans-serif;
}

// Kienan
@mixin kienan {
  font-family: "Kienan", sans-serif;
}

// Metroflex Narrow
@mixin metroflex-narrow {
  font-family: "Metroflex Narrow", sans-serif;
}

// Metroflex Uni
@mixin metroflex-uni {
  font-family: "Metroflex Uni", sans-serif;
}

// Metroflex Wide
@mixin metroflex-wide {
  font-family: "Metroflex Wide", sans-serif;
}

// Fono
@mixin fono {
  font-family: "Fono", sans-serif;
}

// Modus
@mixin modus {
  font-family: "Modus", sans-serif;
}

// Adore Mono
@mixin adore-mono {
  font-family: "Adore Mono", monospace;
}

// Nice Weekend
@mixin nice-weekend {
  font-family: "Nice Weekend", monospace;
}

// Rough Weekend
@mixin rough-weekend {
  font-family: "Rough Weekend", monospace;
}

// Dotted Weekend
@mixin dotted-weekend {
  font-family: "Dotted Weekend", monospace;
}

// Archive
@mixin archive {
  font-family: "Archive", monospace;
}

// Fuqua
@mixin fuqua {
  font-family: "Fuqua", serif;
}

// Fuqua Variable
@mixin fuqua-variable {
  font-family: "Fuqua Variable", serif;
}

// Kamaro
@mixin kamaro {
  font-family: "Kamaro", serif;
}

// Klif
@mixin klif {
  font-family: "Klif", serif;
}

// Karazan
@mixin karazan {
  font-family: "Karazan", serif;
}
