// Section Contain

.section-contain {
  padding-top: 8em;
  padding-bottom: 3em;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  .header {
    padding-bottom: 0.5em;
  }
  .header-text {
    padding-bottom: 2em;
  }
  .subhead {
    text-transform: uppercase;
  }
  .text {
    padding-bottom: 1em;
  }
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .form-link {
    padding-top: 1em;
  }
  .account-header {
    border-bottom: 1px solid $border-black;
    padding-bottom: 2em;
  }
  .invoice-header {
    padding-bottom: 2em;
    .invoice-list {
      margin: 0;
      padding: 0;
    }
  }
  .invoice-footer {
    padding-bottom: 2em;
  }
  .invoice {
    border-bottom: 1px solid $border-black;
    padding-bottom: 0.5em;
    margin-bottom: 1.5em;
  }
  .select-disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

@include media-breakpoint-up(sm) {
  .section-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(md) {
  .section-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(lg) {
  .section-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(xl) {
  .section-contain {
    padding-top: 8em;
    padding-bottom: 3em;
  }
}

@media print {
  .section-contain {
    padding-top: 2em;
    padding-bottom: 2em;
    font-size: 1rem;
    h1 {
      font-size: 1rem;
    }
    .invoice-header {
      padding-bottom: 0em;
    }
  }
}

// Form Length

.form-height {
  padding-bottom: 10em;
}

@include media-breakpoint-up(sm) {
  .form-height {
    padding-bottom: 10em;
  }
}

@include media-breakpoint-up(md) {
  .form-height {
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(lg) {
  .form-height {
    padding-bottom: 3em;
  }
}

@include media-breakpoint-up(xl) {
  .form-height {
    padding-bottom: 3em;
  }
}

// Catalog

.catalog-contain {
  padding-top: 90px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  p {
    font-size: 1rem;
    text-align: center;
  }
  .image-square:hover {
    opacity: 0.8;
  }
  .spec {
    filter: drop-shadow(0.5rem 0.5rem 0.3rem rgb(0, 0, 0, 0.23));
  }
  .grid {
    padding-bottom: 1.5em;
  }
  .tier {
    padding-top: 1em;
  }
  .catalog-title {
    padding-bottom: 1em;
    margin-bottom: 1.5em;
  }
  .catalog-font {
    padding-top: 1em;
    padding-bottom: 2em;
  }
  .catalog-name {
    font-size: 2rem;
  }
  .fonts-image-col {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

@include media-breakpoint-up(sm) {
  .catalog-contain {
    padding-top: 90px;
  }
}

@include media-breakpoint-up(md) {
  .catalog-contain {
    padding-top: 120px;
  }
}

@include media-breakpoint-up(lg) {
  .catalog-contain {
    padding-top: 120px;
  }
}

@include media-breakpoint-up(xl) {
  .catalog-contain {
    padding-top: 120px;
  }
}
