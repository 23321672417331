// Tester

@font-face {
  font-family: "Tester";
  src: url("../../../public/fonts/rumbler/Rumbler-Regular.woff2")
    format("woff2");
  font-display: block;
}

// Omnium

@font-face {
  font-family: "Omnium";
  src: url("../../../public/fonts/omnium/Omnium-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Thin";
  src: url("../../../public/fonts/omnium/Omnium-Thin.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Light";
  src: url("../../../public/fonts/omnium/Omnium-Light.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Medium";
  src: url("../../../public/fonts/omnium/Omnium-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium SemiBold";
  src: url("../../../public/fonts/omnium/Omnium-SemiBold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Bold";
  src: url("../../../public/fonts/omnium/Omnium-Bold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium ExtraBold";
  src: url("../../../public/fonts/omnium/Omnium-ExtraBold.woff2")
    format("woff2");
  font-display: block;
}

// Omnium Wide

@font-face {
  font-family: "Omnium Wide";
  src: url("../../../public/fonts/omnium-wide/OmniumWide-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Wide Thin";
  src: url("../../../public/fonts/omnium-wide/OmniumWide-Thin.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Wide Light";
  src: url("../../../public/fonts/omnium-wide/OmniumWide-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Wide Medium";
  src: url("../../../public/fonts/omnium-wide/OmniumWide-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Wide SemiBold";
  src: url("../../../public/fonts/omnium-wide/OmniumWide-SemiBold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Wide Bold";
  src: url("../../../public/fonts/omnium-wide/OmniumWide-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Wide ExtraBold";
  src: url("../../../public/fonts/omnium-wide/OmniumWide-ExtraBold.woff2")
    format("woff2");
  font-display: block;
}

// Omnium Tagline

@font-face {
  font-family: "Omnium Tagline";
  src: url("../../../public/fonts/omnium-tagline/OmniumTagline-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Tagline Light";
  src: url("../../../public/fonts/omnium-tagline/OmniumTagline-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Tagline Medium";
  src: url("../../../public/fonts/omnium-tagline/OmniumTagline-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Omnium Tagline Bold";
  src: url("../../../public/fonts/omnium-tagline/OmniumTagline-Bold.woff2")
    format("woff2");
  font-display: block;
}

// Arbuckle

@font-face {
  font-family: "Arbuckle";
  src: url("../../../public/fonts/arbuckle/Arbuckle-Bright.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Arbuckle Black";
  src: url("../../../public/fonts/arbuckle/Arbuckle-Black.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Arbuckle Bright";
  src: url("../../../public/fonts/arbuckle/Arbuckle-Bright.woff2")
    format("woff2");
  font-display: block;
}

// @font-face {
//   font-family: "Arbuckle Contour";
//   src: url("../../../public/fonts/arbuckle/Arbuckle-Contour.woff2")
//     format("woff2");
//   font-display: block;
// }

// @font-face {
//   font-family: "Arbuckle Lights";
//   src: url("../../../public/fonts/arbuckle/Arbuckle-Lights.woff2")
//     format("woff2");
//   font-display: block;
// }

// Joachim

@font-face {
  font-family: "Joachim";
  src: url("../../../public/fonts/joachim/Joachim-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Joachim Light";
  src: url("../../../public/fonts/joachim/Joachim-Light.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Joachim Medium";
  src: url("../../../public/fonts/joachim/Joachim-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Joachim Bold";
  src: url("../../../public/fonts/joachim/Joachim-Bold.woff2") format("woff2");
  font-display: block;
}

// Clarice

@font-face {
  font-family: "Clarice";
  src: url("../../../public/fonts/clarice/Clarice-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Regular";
  src: url("../../../public/fonts/clarice/Clarice-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Italic";
  src: url("../../../public/fonts/clarice/Clarice-Italic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Medium";
  src: url("../../../public/fonts/clarice/Clarice-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Medium Italic";
  src: url("../../../public/fonts/clarice/Clarice-MediumItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Bold";
  src: url("../../../public/fonts/clarice/Clarice-Bold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Bold Italic";
  src: url("../../../public/fonts/clarice/Clarice-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Black";
  src: url("../../../public/fonts/clarice/Clarice-Black.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Black Italic";
  src: url("../../../public/fonts/clarice/Clarice-BlackItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Clarice Inline";
  src: url("../../../public/fonts/clarice/Clarice-Inline.woff2") format("woff2");
  font-display: block;
}

// District

@font-face {
  font-family: "District";
  src: url("../../../public/fonts/district/District-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Thin";
  src: url("../../../public/fonts/district/District-Thin.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Thin Italic";
  src: url("../../../public/fonts/district/District-ThinItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Light";
  src: url("../../../public/fonts/district/District-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Light Italic";
  src: url("../../../public/fonts/district/District-LightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Regular";
  src: url("../../../public/fonts/district/District-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Italic";
  src: url("../../../public/fonts/district/District-Italic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Medium";
  src: url("../../../public/fonts/district/District-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Medium Italic";
  src: url("../../../public/fonts/district/District-MediumItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District SemiBold";
  src: url("../../../public/fonts/district/District-SemiBold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District SemiBold Italic";
  src: url("../../../public/fonts/district/District-SemiBoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Bold";
  src: url("../../../public/fonts/district/District-Bold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Bold Italic";
  src: url("../../../public/fonts/district/District-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Black";
  src: url("../../../public/fonts/district/District-Black.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "District Black Italic";
  src: url("../../../public/fonts/district/District-BlackItalic.woff2")
    format("woff2");
  font-display: block;
}

// Homage Script

@font-face {
  font-family: "Homage Script";
  src: url("../../../public/fonts/homage-script/HomageScript.woff2")
    format("woff2");
  font-display: block;
}

// Homage Condensed

@font-face {
  font-family: "Homage Condensed";
  src: url("../../../public/fonts/homage-condensed/HomageCondensed-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Homage Condensed Medium";
  src: url("../../../public/fonts/homage-condensed/HomageCondensed-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Homage Condensed Medium Italic";
  src: url("../../../public/fonts/homage-condensed/HomageCondensed-MediumItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Homage Condensed Black";
  src: url("../../../public/fonts/homage-condensed/HomageCondensed-Black.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Homage Condensed Black Italic";
  src: url("../../../public/fonts/homage-condensed/HomageCondensed-BlackItalic.woff2")
    format("woff2");
  font-display: block;
}

// Kienan

@font-face {
  font-family: "Kienan";
  src: url("../../../public/fonts/kienan/Kienan.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kienan Light";
  src: url("../../../public/fonts/kienan/KienaLig.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kienan Regular";
  src: url("../../../public/fonts/kienan/Kienan.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kienan Bold";
  src: url("../../../public/fonts/kienan/KienaBol.woff2") format("woff2");
  font-display: block;
}

// Metroflex Narrow

@font-face {
  font-family: "Metroflex Narrow";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Light";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Light Oblique";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-LightOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Regular";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Oblique";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-Oblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Medium";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Medium Oblique";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-MediumOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Bold";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Bold Oblique";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-BoldOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Heavy";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-Heavy.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Narrow Heavy Oblique";
  src: url("../../../public/fonts/metroflex-narrow/MetroflexNarrow-HeavyOblique.woff2")
    format("woff2");
  font-display: block;
}

// Metroflex Uni

@font-face {
  font-family: "Metroflex Uni";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Light";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Light Oblique";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-LightOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Regular";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Oblique";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-Oblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Medium";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Medium Oblique";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-MediumOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Bold";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Bold Oblique";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-BoldOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Heavy";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-Heavy.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Uni Heavy Oblique";
  src: url("../../../public/fonts/metroflex-uni/MetroflexUni-HeavyOblique.woff2")
    format("woff2");
  font-display: block;
}

// Metroflex Wide

@font-face {
  font-family: "Metroflex Wide";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Light";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Light Oblique";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-LightOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Regular";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Oblique";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-Oblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Medium";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Medium Oblique";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-MediumOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Bold";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Bold Oblique";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-BoldOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Heavy";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-Heavy.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Metroflex Wide Heavy Oblique";
  src: url("../../../public/fonts/metroflex-wide/MetroflexWide-HeavyOblique.woff2")
    format("woff2");
  font-display: block;
}

// Fono

@font-face {
  font-family: "Fono";
  src: url("../../../public/fonts/fono/Fono-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fono Compressed";
  src: url("../../../public/fonts/fono/Fono-Compressed.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fono Compressed Oblique";
  src: url("../../../public/fonts/fono/Fono-CompressedOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fono Medium";
  src: url("../../../public/fonts/fono/Fono-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fono Medium Oblique";
  src: url("../../../public/fonts/fono/Fono-MediumOblique.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fono Expanded";
  src: url("../../../public/fonts/fono/Fono-Expanded.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fono Expanded Oblique";
  src: url("../../../public/fonts/fono/Fono-ExpandedOblique.woff2")
    format("woff2");
  font-display: block;
}

// Modus

@font-face {
  font-family: "Modus Regular";
  src: url("../../../public/fonts/modus/Modus-Regular.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Modus Italic";
  src: url("../../../public/fonts/modus/Modus-Italic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Modus SemiBold";
  src: url("../../../public/fonts/modus/Modus-SemiBold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Modus SemiBold Italic";
  src: url("../../../public/fonts/modus/Modus-SemiBoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Modus Bold";
  src: url("../../../public/fonts/modus/Modus-Bold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Modus Bold Italic";
  src: url("../../../public/fonts/modus/Modus-BoldItalic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Modus Headline Light";
  src: url("../../../public/fonts/modus/Modus-HeadlineLight.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Modus Headline ExtraBold";
  src: url("../../../public/fonts/modus/Modus-HeadlineExtraBold.woff2")
    format("woff2");
  font-display: block;
}

// Adore Mono

@font-face {
  font-family: "Adore Mono";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Hairline";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Hairline.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Hairline Italic";
  src: url("../../../public/fonts/adore-mono/AdoreMono-HairlineItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Thin";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Thin.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Thin Italic";
  src: url("../../../public/fonts/adore-mono/AdoreMono-ThinItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Light";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Light Italic";
  src: url("../../../public/fonts/adore-mono/AdoreMono-LightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Regular";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Italic";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Italic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Medium";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Medium.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Medium Italic";
  src: url("../../../public/fonts/adore-mono/AdoreMono-MediumItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Bold";
  src: url("../../../public/fonts/adore-mono/AdoreMono-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Adore Mono Bold Italic";
  src: url("../../../public/fonts/adore-mono/AdoreMono-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

// Nice Weekend

@font-face {
  font-family: "Nice Weekend";
  src: url("../../../public/fonts/nice-weekend/NiceWeekend-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Nice Weekend Light";
  src: url("../../../public/fonts/nice-weekend/NiceWeekend-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Nice Weekend Light Italic";
  src: url("../../../public/fonts/nice-weekend/NiceWeekend-LightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Nice Weekend Regular";
  src: url("../../../public/fonts/nice-weekend/NiceWeekend-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Nice Weekend Italic";
  src: url("../../../public/fonts/nice-weekend/NiceWeekend-Italic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Nice Weekend Bold";
  src: url("../../../public/fonts/nice-weekend/NiceWeekend-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Nice Weekend Bold Italic";
  src: url("../../../public/fonts/nice-weekend/NiceWeekend-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

// Rough Weekend

@font-face {
  font-family: "Rough Weekend";
  src: url("../../../public/fonts/rough-weekend/RoughWeekend-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Rough Weekend Light";
  src: url("../../../public/fonts/rough-weekend/RoughWeekend-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Rough Weekend Light Italic";
  src: url("../../../public/fonts/rough-weekend/RoughWeekend-Italic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Rough Weekend Regular";
  src: url("../../../public/fonts/rough-weekend/RoughWeekend-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Rough Weekend Italic";
  src: url("../../../public/fonts/rough-weekend/RoughWeekend-Italic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Rough Weekend Bold";
  src: url("../../../public/fonts/rough-weekend/RoughWeekend-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Rough Weekend Bold Italic";
  src: url("../../../public/fonts/rough-weekend/RoughWeekend-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

// Dotted Weekend

@font-face {
  font-family: "Dotted Weekend";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend ExtraLight";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-ExtraLight.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend ExtraLight Italic";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-ExtraLightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Light";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-Light.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Light Italic";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-LightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Regular";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Italic";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-Italic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Bold";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Bold Italic";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend ExtraBold";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-ExtraBold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend ExtraBold Italic";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-ExtraBoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Black";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-Black.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Dotted Weekend Black Italic";
  src: url("../../../public/fonts/dotted-weekend/DottedWeekend-BlackItalic.woff2")
    format("woff2");
  font-display: block;
}

// Archive

@font-face {
  font-family: "Archive";
  src: url("../../../public/fonts/archive/Archive-Black.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Light";
  src: url("../../../public/fonts/archive/Archive-Light.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Light Italic";
  src: url("../../../public/fonts/archive/Archive-LightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Regular";
  src: url("../../../public/fonts/archive/Archive-Regular.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Italic";
  src: url("../../../public/fonts/archive/Archive-Italic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Bold";
  src: url("../../../public/fonts/archive/Archive-Bold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Bold Italic";
  src: url("../../../public/fonts/archive/Archive-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Black";
  src: url("../../../public/fonts/archive/Archive-Black.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Black Italic";
  src: url("../../../public/fonts/archive/Archive-BlackItalic.woff2")
    format("woff2");
  font-display: block;
}

// Archive Matrix

@font-face {
  font-family: "Archive Matrix";
  src: url("../../../public/fonts/archive-matrix/ArchiveMatrix-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Matrix Bold";
  src: url("../../../public/fonts/archive-matrix/ArchiveMatrix-Bold.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Archive Matrix Bold Italic";
  src: url("../../../public/fonts/archive-matrix/ArchiveMatrix-BoldItalic.woff2")
    format("woff2");
  font-display: block;
}

// Fuqua

@font-face {
  font-family: "Fuqua";
  src: url("../../../public/fonts/fuqua/Fuqua-Regular.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Variable";
  src: url("../../../public/fonts/fuqua/FuquaVF.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Variable Italic";
  src: url("../../../public/fonts/fuqua/FuquaVF-Italic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Thin";
  src: url("../../../public/fonts/fuqua/Fuqua-Thin.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Thin Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-ThinItalic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua ExtraLight";
  src: url("../../../public/fonts/fuqua/Fuqua-ExtraLight.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua ExtraLight Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-ExtraLightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Light";
  src: url("../../../public/fonts/fuqua/Fuqua-Light.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Light Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-LightItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Regular";
  src: url("../../../public/fonts/fuqua/Fuqua-Regular.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-Italic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Medium";
  src: url("../../../public/fonts/fuqua/Fuqua-Medium.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Medium Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-MediumItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua SemiBold";
  src: url("../../../public/fonts/fuqua/Fuqua-SemiBold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua SemiBold Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-SemiBoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Bold";
  src: url("../../../public/fonts/fuqua/Fuqua-Bold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Bold Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-BoldItalic.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua ExtraBold";
  src: url("../../../public/fonts/fuqua/Fuqua-ExtraBold.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua ExtraBold Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-ExtraBoldItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Black";
  src: url("../../../public/fonts/fuqua/Fuqua-Black.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Black Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-BlackItalic.woff2")
    format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Ultra";
  src: url("../../../public/fonts/fuqua/Fuqua-Ultra.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Ultra Italic";
  src: url("../../../public/fonts/fuqua/Fuqua-UltraItalic.woff2")
    format("woff2");
  font-display: block;
}

// Fuqua Variable

@font-face {
  font-family: "Fuqua Variable";
  src: url("../../../public/fonts/fuqua/FuquaVF.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Variable Roman";
  src: url("../../../public/fonts/fuqua/FuquaVF.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Fuqua Variable Italic";
  src: url("../../../public/fonts/fuqua/FuquaVF-Italic.woff2") format("woff2");
  font-display: block;
}

// Kamaro

@font-face {
  font-family: "Kamaro";
  src: url("../../../public/fonts/kamaro/KamarArt.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Art";
  src: url("../../../public/fonts/kamaro/KamarArt.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Art Gradient";
  src: url("../../../public/fonts/kamaro/KamarArtGra.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Art Ytalik";
  src: url("../../../public/fonts/kamaro/KamarArtYta.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Big";
  src: url("../../../public/fonts/kamaro/KamarBig.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Big Ytalik";
  src: url("../../../public/fonts/kamaro/KamarBigYta.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Fine";
  src: url("../../../public/fonts/kamaro/KamarFin.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Fine Ytalik";
  src: url("../../../public/fonts/kamaro/KamarFinYta.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Joy Inz";
  src: url("../../../public/fonts/kamaro/KamarJoyInz.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Joy Out";
  src: url("../../../public/fonts/kamaro/KamarJoyOut.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Joy Ultra";
  src: url("../../../public/fonts/kamaro/KamarJoyUlt.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Joy X";
  src: url("../../../public/fonts/kamaro/KamarJoyX.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Optik";
  src: url("../../../public/fonts/kamaro/KamarOpt.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Slang Inz";
  src: url("../../../public/fonts/kamaro/KamarSlaInz.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Kamaro Slang Out";
  src: url("../../../public/fonts/kamaro/KamarSlaOut.woff2") format("woff2");
  font-display: block;
}

// Klif

@font-face {
  font-family: "Klif";
  src: url("../../../public/fonts/klif/KlifFat.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Klif Fat";
  src: url("../../../public/fonts/klif/KlifFat.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Klif Mix";
  src: url("../../../public/fonts/klif/KlifMix.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: "Klif Slim";
  src: url("../../../public/fonts/klif/KlifSli.woff2") format("woff2");
  font-display: block;
}

// Karazan

@font-face {
  font-family: "Karazan";
  src: url("../../../public/fonts/karazan/Karazan.woff2") format("woff2");
  font-display: block;
}
