//Colors

.section-bg {
  background: #ffffff;
  color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #000000;
  }
  .menu-icon {
    background: #ffffff;
    color: #000000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 80%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 80%);
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .fonts-use {
    .scale {
      filter: grayscale(1);
    }
  }
  .fonts-square {
    .scale {
      filter: grayscale(1);
    }
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.home-bg {
  background: #000000;
  color: #ffffff;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #ffffff;
  }
  .menu-icon {
    background: #000000;
    color: #ffffff;
  }
  .vw-spec {
    a {
      text-decoration: none;
    }
    a:hover {
      color: lighten(#000000, 50%);
    }
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 20%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 20%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 20%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 20%);
  //   border-bottom: 1px solid lighten(#000000, 20%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 20%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 20%);
  // }
  .fonts-use {
    .scale {
      filter: grayscale(1);
    }
  }
  .fonts-square {
    .scale {
      filter: grayscale(1);
    }
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.pipe-bg {
  background: #333333;
  color: #ffffff;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #333333;
    color: #ffffff;
  }
  .vw-spec {
    a {
      text-decoration: none;
    }
    a:hover {
      color: lighten(#333333, 50%);
    }
  }
}

.catalog-bg {
  background: #1a1a1a;
  color: #00ff00;
  a {
    color: #c9c9c9;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #c9c9c9;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #1a1a1a;
    color: #00ff00;
  }
  //Borders
  .catalog-title {
    border-bottom: 1px solid lighten(#1a1a1a, 10%);
  }

  .catalog-font {
    border-bottom: 1px solid lighten(#1a1a1a, 10%);
  }

  .catalog-text {
    a {
      color: #00ff00;
      text-decoration: none;
    }
    a:hover {
      color: darken(#00ff00, 5%);
    }
  }
}

.catalog-bg-font {
  background: #ffffff;
  color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #000000;
    color: #ffffff;
  }
  //Borders
  .catalog-title {
    border-bottom: 1px solid lighten(#000000, 50%);
  }

  .catalog-font {
    border-bottom: 1px solid lighten(#000000, 50%);
  }

  .catalog-text {
    a {
      color: #000000;
      text-decoration: none;
    }
    a:hover {
      color: lighten(#000000, 5%);
    }
  }
}

.stack-bg {
  background: #000000;
  color: #ffffff;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #000000;
    color: #ffffff;
  }
  //Borders
  .catalog-title {
    border-bottom: 1px solid lighten(#1a1a1a, 10%);
  }

  .catalog-font {
    border-bottom: 1px solid lighten(#1a1a1a, 10%);
  }

  .catalog-text {
    a {
      color: #ffffff;
      text-decoration: none;
    }
    a:hover {
      color: darken(#ffffff, 5%);
    }
  }
}

.client-trial-bg {
  background: #d5d5d5;
  color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #000000;
  }
  .menu-icon {
    background: #ffffff;
    color: #000000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 80%);
  }
  .fonts-use {
    .scale {
      filter: grayscale(1);
    }
  }
  .fonts-square {
    .scale {
      filter: grayscale(1);
    }
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.tester-bg {
  background: #ffffff;
  color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #000000;
    color: #ffffff;
  }
}

.omnium-bg {
  background: #041c19;
  color: #01fed7;
  a {
    color: #e46bff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #e46bff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #041c19;
    color: #01fed7;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#041c19, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#041c19, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#041c19, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#262628, 10%);
  //   border-bottom: 1px solid lighten(#262628, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#262628, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#262628, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.omnium-wide-bg {
  background: #1f1b2b;
  color: #bebee1;
  a {
    color: #00f1e9;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #00f1e9;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #1f1b2b;
    color: #bebee1;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#1f1b2b, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#1f1b2b, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#1f1b2b, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#1f1b2b, 10%);
  //   border-bottom: 1px solid lighten(#1f1b2b, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#1f1b2b, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#1f1b2b, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.omnium-tagline-bg {
  background: #1b252b;
  color: #00ff26;
  a {
    color: #55cf9a;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #55cf9a;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #1b252b;
    color: #00ff26;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#1b252b, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#1b252b, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#1b252b, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#1b252b, 10%);
  //   border-bottom: 1px solid lighten(#1b252b, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#1b252b, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#1b252b, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.arbuckle-bg {
  background: #1d101b;
  color: #ff0066;
  a {
    color: #00ff33;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #00ff33;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #00ff33;
    color: #ff0066;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#1d101b, 5%);
  }
  .unit-small {
    border-top: 1px solid lighten(#1d101b, 5%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#1d101b, 5%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#1d101b, 5%);
  //   border-bottom: 1px solid lighten(#1d101b, 5%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#1d101b, 5%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#1d101b, 5%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.joachim-bg {
  background: #5b0609;
  color: #22994b;
  a {
    color: #feca2f;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #feca2f;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #5b0609;
    color: #feca2f;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#5b0609, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#5b0609, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#5b0609, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#5b0609, 10%);
  //   border-bottom: 1px solid lighten(#5b0609, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#5b0609, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#5b0609, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.district-bg {
  background: #ff0000;
  color: #000000;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #ff0000;
    color: #000000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#ff0000, 20%);
  }
  .unit-small {
    border-top: 1px solid lighten(#ff0000, 20%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#ff0000, 20%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#ff0000, 20%);
  //   border-bottom: 1px solid lighten(#ff0000, 20%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#ff0000, 20%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#ff0000, 20%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.homage-script-bg {
  background: #09145f;
  color: #ffffff;
  a {
    color: #00eaff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #00eaff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #00eaff;
    color: #09145f;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#09145f, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#09145f, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#09145f, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#683c0c, 10%);
  //   border-bottom: 1px solid lighten(#683c0c, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#683c0c, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#683c0c, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.homage-script-bg-orig {
  background: #683c0c;
  color: #ffe048;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #683c0c;
    color: #ffe048;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#683c0c, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#683c0c, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#683c0c, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#683c0c, 10%);
  //   border-bottom: 1px solid lighten(#683c0c, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#683c0c, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#683c0c, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.homage-condensed-bg {
  background: #2c1605;
  color: #f3e3b8;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #2c1605;
    color: #f3e3b8;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#2c1605, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#2c1605, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#2c1605, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#2c1605, 10%);
  //   border-bottom: 1px solid lighten(#2c1605, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#2c1605, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#2c1605, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.kienan-bg {
  background: #ffffff;
  color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #000000;
  }
  .menu-icon {
    background: #ffffff;
    color: #000000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 80%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 80%);
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .fonts-use {
    .scale {
      filter: grayscale(1);
    }
  }
  .fonts-square {
    .scale {
      filter: grayscale(1);
    }
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.metroflex-narrow-bg {
  background: #320000;
  color: #ff0000;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #320000;
    color: #ff0000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#320000, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#320000, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#320000, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#320000, 10%);
  //   border-bottom: 1px solid lighten(#320000, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#320000, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#320000, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.metroflex-uni-bg {
  background: #08143f;
  color: #c3ff00;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #08143f;
    color: #c3ff00;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#08143f, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#08143f, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#08143f, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#08143f, 10%);
  //   border-bottom: 1px solid lighten(#08143f, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#08143f, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#08143f, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.metroflex-wide-bg {
  background: #ff4400;
  color: #80ff00;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #ff4400;
    color: #80ff00;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#ff4400, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#ff4400, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#ff4400, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#ff4400, 10%);
  //   border-bottom: 1px solid lighten(#ff4400, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#ff4400, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#ff4400, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.adore-mono-bg {
  background: #d6d6d6;
  color: #000000;
  a {
    color: #0800ff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #0800ff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #d6d6d6;
    color: #000000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#d6d6d6, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#d6d6d6, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#d6d6d6, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#d6d6d6, 10%);
  //   border-bottom: 1px solid lighten(#d6d6d6, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#d6d6d6, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#d6d6d6, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.rough-weekend-bg {
  background: #f1f2ee;
  color: #1a445e;
  a {
    color: #ff5100;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ff5100;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #ff5100;
  }
  .menu-icon {
    background: #1a445e;
    color: #f1f2ee;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 80%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 80%);
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.dotted-weekend-bg {
  background: #182024;
  color: #dcff3e;
  a {
    color: #f1f2ee;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #f1f2ee;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #f1f2ee;
  }
  .menu-icon {
    background: #dcff3e;
    color: #182024;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#182024, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#182024, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#182024, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 80%);
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.fuqua-bg {
  background: #1a1a1a;
  color: #ffc10d;
  a {
    color: #d7d7d7;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #d7d7d7;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #d7d7d7;
  }
  .menu-icon {
    background: #ffc10d;
    color: #1a1a1a;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#1a1a1a, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#1a1a1a, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#1a1a1a, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#1a1a1a, 10%);
  //   border-bottom: 1px solid lighten(#1a1a1a, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#1a1a1a, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#1a1a1a, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.fuqua-variable-bg {
  background: #1a1a1a;
  color: #0090ff;
  a {
    color: #ffc10d;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffc10d;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #ffc10d;
  }
  .menu-icon {
    background: #ffc10d;
    color: #1a1a1a;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#1a1a1a, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#1a1a1a, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#1a1a1a, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#1a1a1a, 10%);
  //   border-bottom: 1px solid lighten(#1a1a1a, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#1a1a1a, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#1a1a1a, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.clarice-bg {
  background: #3e001f;
  color: #ffe7c5;
  a {
    color: #f11a7b;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #f11a7b;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #f11a7b;
    color: #ffe7c5;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#3e001f, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#3e001f, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#3e001f, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#3e001f, 10%);
  //   border-bottom: 1px solid lighten(#3e001f, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#3e001f, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#3e001f, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.fono-bg {
  background: #fcfcfc;
  color: #6528f7;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #000000;
    color: #ede4ff;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#fcfcfc, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#fcfcfc, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#fcfcfc, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#fcfcfc, 10%);
  //   border-bottom: 1px solid lighten(#fcfcfc, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#fcfcfc, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#fcfcfc, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.modus-bg {
  background: #0099ff;
  color: #000000;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #ffffff;
  }
  .menu-icon {
    background: #000000;
    color: #0099ff;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 10%);
  //   border-bottom: 1px solid lighten(#000000, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.nice-weekend-bg {
  background: #3d1766;
  color: #ff0032;
  a {
    color: #c780fa;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #c780fa;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .menu-icon {
    background: #c780fa;
    color: #ff0032;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#3d1766, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#3d1766, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#3d1766, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#3d1766, 10%);
  //   border-bottom: 1px solid lighten(#3d1766, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#3d1766, 10%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#3d1766, 10%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.archive-bg {
  background: #1a1a1a;
  color: #b3ff00;
  a {
    color: #ff00f2;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ff00f2;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #ff00f2;
  }
  .menu-icon {
    background: #161926;
    color: #b3ff00;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#161926, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#161926, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#161926, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#161926, 10%);
  //   border-bottom: 1px solid lighten(#161926, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.archive-matrix-bg {
  background: #1a1a1a;
  color: #00ffb2;
  a {
    color: #fe1111;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #fe1111;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #fe1111;
  }
  .menu-icon {
    background: #1a1a1a;
    color: #00ffb2;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#1a1a1a, 10%);
  }
  .unit-small {
    border-top: 1px solid lighten(#1a1a1a, 10%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#1a1a1a, 10%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#161926, 10%);
  //   border-bottom: 1px solid lighten(#161926, 10%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.kamaro-bg {
  background: #0000be;
  color: #ff0000;
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #000000;
  }
  .menu-icon {
    background: #ffffff;
    color: #000000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid #ff0000;
  }
  .unit-small {
    border-top: 1px solid #ff0000;
  }
  .char-border {
    border-top: 1px solid #ff0000;
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 80%);
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .fonts-use {
    .scale {
      filter: grayscale(1);
    }
  }
  .fonts-square {
    .scale {
      filter: grayscale(1);
    }
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.klif-bg {
  background: #90fe00;
  color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #000000;
  }
  .menu-icon {
    background: #000000;
    color: #90fe00;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 80%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 80%);
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .fonts-use {
    .scale {
      filter: grayscale(1);
    }
  }
  .fonts-square {
    .scale {
      filter: grayscale(1);
    }
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}

.karazan-bg {
  background: #ffffff;
  color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .axes {
    color: #000000;
  }
  .menu-icon {
    background: #ffffff;
    color: #000000;
  }
  //Borders
  .unit-big {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .unit-small {
    border-top: 1px solid lighten(#000000, 80%);
  }
  .char-border {
    border-bottom: 1px solid lighten(#000000, 80%);
  }
  // .fonts-words {
  //   border-top: 1px solid lighten(#000000, 80%);
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-use {
  //   border-bottom: 1px solid lighten(#000000, 80%);
  // }
  // .fonts-license {
  //   border-top: 1px solid lighten(#000000, 80%);
  // }
  .fonts-use {
    .scale {
      filter: grayscale(1);
    }
  }
  .fonts-square {
    .scale {
      filter: grayscale(1);
    }
  }
  .buy-button {
    color: #ffffff;
    text-decoration: none;
  }
  .relation-button {
    a {
      color: #000000;
      text-decoration: none;
    }
  }
}
