// Message

.message-contain {
  padding-top: 0em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

// Loader

.loader-contain {
  padding-top: 0em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
