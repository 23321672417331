// Home

.home-contain {
  padding-top: 90px;
  text-align: center;
  font-size: 8vw;
  p {
    font-size: 1rem;
  }
}

@include media-breakpoint-up(sm) {
  .home-contain {
    padding-top: 90px;
  }
}

@include media-breakpoint-up(md) {
  .home-contain {
    padding-top: 120px;
  }
}

@include media-breakpoint-up(lg) {
  .home-contain {
    padding-top: 120px;
  }
}

@include media-breakpoint-up(xl) {
  .home-contain {
    padding-top: 120px;
  }
}

// Square

.square-contain {
  padding-top: 90px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  p {
    font-size: 1rem;
    text-align: center;
  }
  .image-square:hover {
    opacity: 0.8;
  }
  .spec {
    filter: drop-shadow(0.5rem 0.5rem 0.3rem rgb(0, 0, 0, 0.23));
  }
  .grid {
    padding-bottom: 1.5em;
  }
  .tier {
    padding-top: 1em;
  }
}

@include media-breakpoint-up(sm) {
  .square-contain {
    padding-top: 90px;
  }
}

@include media-breakpoint-up(md) {
  .square-contain {
    padding-top: 120px;
  }
}

@include media-breakpoint-up(lg) {
  .square-contain {
    padding-top: 120px;
  }
}

@include media-breakpoint-up(xl) {
  .square-contain {
    padding-top: 120px;
  }
}

// Collect

.collect-contain {
  padding-top: 90px;
  text-align: left;
  font-size: 10vw;
  p {
    font-size: 1rem;
  }
  .library-item {
    border-bottom: 1px solid $border-black;
  }
}

@include media-breakpoint-up(sm) {
  .collect-contain {
    padding-top: 90px;
    font-size: 10vw;
  }
}

@include media-breakpoint-up(md) {
  .collect-contain {
    padding-top: 120px;
    font-size: 6vw;
  }
}

@include media-breakpoint-up(lg) {
  .collect-contain {
    padding-top: 120px;
    font-size: 6vw;
  }
}

@include media-breakpoint-up(xl) {
  .collect-contain {
    padding-top: 120px;
    font-size: 6vw;
  }
}
